.HeaderSearch {
    position: relative;
}

.HeaderSearch input,
.HeaderSearch input:focus,
.HeaderSearch input:focus-visible {
    background: var(--color-red);
    border: 1px solid rgba(255, 255, 255, 0.2);
    min-height: 40px;
    border-radius: 8px;
    margin: 10px 0 15px 0;
    outline: unset;
    color: white;
    font-family: IranSans;
    padding: 0 10px;
    width: 100%;
}

.HeaderSearch input::placeholder {
    color: rgba(255, 255, 255, 0.8);
}

.LiveSearch {
    position: absolute;
    background: rgb(255 255 255 / 90%);
    border: 1px solid #ff6666;
    padding: 15px;
    border-radius: 5px;
    opacity: 0;
    top: 10px;
    transition: .2s all ease;
    z-index: 1;
    transform: scale(0.9);
    backdrop-filter: blur(3px);
    visibility: hidden;
    left: 0;
    right: 0;
}

.LiveSearchActive {
    visibility: visible;
    opacity: 1;
    top: 50px;
    transform: scale(1.0);
}