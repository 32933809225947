.HomeFooter {
    box-shadow: 0px -8px 44px 0px rgba(20, 25, 44, 0.08);
    border-radius: 25px 25px 0 0;
    height: 60px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.HomeFooter ul {
    margin: 0;
    padding: 0 15px;
    width: 100%;
}

.HomeFooter li {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 20%;
    position: relative;
}

.HomeFooter li > a,
.HomeFooter li > span {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.HomeFooter li > a > span,
.HomeFooter li > span {
    font-size: 12px;
    width: 100%;
    color: var(--color-gray);
    text-align: center;
}

.HomeFooter li i {
    font-size: 20px;
    color: darkgray;
}

.FooterAddIcon {
    margin-top: -56px;
}

.FooterAddIcon a {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 50px!important;
    height: 50px!important;
    background: var(--color-red);
}

.FooterAddIcon i {
    color: white!important;
}

.ProvinceSelector {
    box-shadow: 0px -8px 44px 0px rgba(20, 25, 44, 0.08);
    background: rgb(255 255 255 / 85%);
    position: fixed;
    bottom: -500px;
    left: 0;
    height: 520px;
    z-index: 999;
    border-radius: 25px 25px 0 0;
    backdrop-filter: blur(5px);
    transition: .8s all ease;
    padding: 15px;
}

.ProvinceSelectorActive {
    bottom: 0;
}

.FooterLinkActive span,
.FooterLinkActive i {
    color: var(--color-red)!important;
}

.ProvSelectorClose {
    position: absolute;
    left: 20px;
}

.ProvSelectorClose i {
    font-size: 10px!important;
}

.ProvinceList li {
    width: 50%;
}

.ProvinceList li span {
    font-size: 15px;
    color: var(--color-gray)
}

.ProvinceList li i {
    font-size: 20px;
    margin-left: 5px;
    color: var(--color-gray);
}

.SelectedProvince span {
    color: var(--color-red)!important;
    font-weight: bold;
}