.HomeHeader {
    background: var(--color-red);
    padding: 20px;
    margin-bottom: -20px;
}

.HeaderNav span {
    transition: .5s all ease;
}

.HeaderNav::after,
.HeaderNav::before {
    content: "";
    width: 30px;
    height: 2px;
    background: #fff;
    display: block;
    margin-bottom: 5px;
    transition: all .5s ease;
    transform: rotate(0deg);
    position: relative;
    top: 0;
    z-index: 9999;
}

.HeaderNavActive::after {
    transform: rotate(45deg);
    width: 25px;
}

.HeaderNavActive::before {
    transform: rotate(-45deg);
    width: 25px;
    top: 7px;
}

.HeaderMenu {
    width: 70%;
    height: 100%;
    background: white;
    position: fixed;
    top: 0;
    right: -70%;
    z-index: 9999;
    transition: .5s all ease;
    overflow-y: auto;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 25%);
}

.HeaderMenuOpen {
    right: 0;
}

.HeaderNavActive span {
    position: fixed;
    left: 0;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 9998;
    backdrop-filter: blur(3px);
    background: rgb(255 255 255 / 15%);
}

.Loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--color-red);
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.HeaderLogo {
    position: relative;
}

.DefaultProvince {
    position: absolute;
    background: white;
    color: var(--color-red);
    font-size: 10px;
    border-radius: 3px;
    padding: 2px 3px;
    top: 6px;
    white-space: nowrap;
    margin-right: 5px;
}

.SidebarHeader {
    height: 60px;
    background: var(--color-red);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
}