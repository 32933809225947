html,
body {
    padding: 0;
    margin: 0;
    direction: rtl;
    font-family: 'IranSans';
    background: #F4F4F4;
}

@font-face {
    font-family: 'IranSans';
    src: url('../fonts/iransans/IRANSansWeb.eot');
    src: url('../fonts/iransans/IRANSansWeb.eot?#iefix') format('embedded-opentype'),
    url('../fonts/iransans/IRANSansWeb.woff2') format('woff2'),
    url('../fonts/iransans/IRANSansWeb.woff') format('woff'),
    url('../fonts/iransans/IRANSansWeb.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

:root {
    --color-gray: #595959;
    --color-red: #f55;
    --color-green: #4caf50;
}

* {
    text-decoration: unset;
}

a,a:link {
    color: black;
}

/* BS */

.list-unstyled {
    list-style: none;
}

.d-flex {
    display: flex;
}

.d-block {
    display: block;
}

.justify-content-end {
    justify-content: flex-end;
}

.flex-wrap {
    flex-wrap: wrap;
}

.m-0 {
    margin: 0;
}

.p-0 {
    padding: 0;
}

.align-items-center {
    align-items: center;
}

.align-items-start {
    align-items: start;
}

.justify-content-between {
    justify-content: space-between;
}

.justify-content-center {
    justify-content: center;
}

.infinite-scroll-component {
    overflow: hidden !important;
}

.lazy-load-image-loaded {
    width: 100%;
}

/* Icon Set */

.icon::before {
    font-family: 'Line Awesome Free';
    font-weight: 600;
    font-style: normal;
}

.icon[data-cat-id="1"]::before {
    content:"\f1b9"
}
.icon[data-cat-id="2"]::before {
    content:"\f10b";
}

.icon[data-cat-id="3"]::before {
    content:"\f015";
}

.icon[data-cat-id="4"]::before {
    content: "\f4b8";
}

.icon[data-cat-id="5"]::before {
    content:"\f7b6";
}

.icon[data-cat-id="6"]::before {
    content:"\f7d9";
}

.icon[data-cat-id="7"]::before {
    content:"\f64f";
}

.icon[data-cat-id="8"]::before {
    content:"\f553";
}

.icon[data-cat-id="9"]::before {
    content:"\f77d";
}

.icon[data-cat-id="10"]::before {
    content:"\f12e";
}

.icon[data-cat-id="12"]::before {
    content:"\f818";
}

.icon[data-cat-id="14"]::before {
    content:"\f275";
}

.icon[data-cat-id="15"]::before {
    content:"\f630";
}

.icon[data-cat-id="16"]::before {
    content:"\f807";
}

.icon[data-cat-id="17"]::before {
    content:"\f2b5";
}

/* Subcat */

.icon[data-sub-cat-id="3"]::before,
.icon[data-subsub-cat-id="66"]::before {
    content: "\f10b";
}
.icon[data-sub-cat-id="4"]::before {
    content: "\f10a";
}
.icon[data-sub-cat-id="6"]::before,
.icon[data-subsub-cat-id="88"]::before {
    content: "\f109";
}
.icon[data-sub-cat-id="7"]::before,
.icon[data-subsub-cat-id="89"]::before {
    content: "\f108";
}
.icon[data-sub-cat-id="8"]::before,
.icon[data-subsub-cat-id="262"]::before {
    content: "\f025";
}
.icon[data-sub-cat-id="9"]::before,
.icon[data-subsub-cat-id="51"]::before {
    content: "\f083";
}
.icon[data-sub-cat-id="10"]::before,
.icon[data-subsub-cat-id="72"]::before,
.icon[data-subsub-cat-id="43"]::before {
    content: "\f11b";
}
.icon[data-sub-cat-id="20"]::before {
    content: "\f095";
}
.icon[data-sub-cat-id="77"]::before,
.icon[data-subsub-cat-id="44"]::before {
    content: "\f02f";
}
.icon[data-sub-cat-id="103"]::before {
    content: "\f305";
}
.icon[data-sub-cat-id="1"]::before {
    content: "\f1b9";
}
.icon[data-sub-cat-id="5"]::before,
.icon[data-subsub-cat-id="86"]::before {
    content: "\f21c";
}
.icon[data-sub-cat-id="11"]::before,
.icon[data-subsub-cat-id="87"]::before {
    content: "\f206";
}
.icon[data-sub-cat-id="102"]::before,
.icon[data-subsub-cat-id="122"]::before {
    content: "\f21a";
}
.icon[data-subsub-cat-id="59"]::before {
    content: "\f5e4";
}
.icon[data-subsub-cat-id="60"]::before {
    content: "\f0d1";
}
.icon[data-subsub-cat-id="61"]::before {
    content: "\f5df";
}
.icon[data-subsub-cat-id="237"]::before {
    content: "\f5de";
}
.icon[data-subsub-cat-id="269"]::before {
    content: "\f128";
}
.icon[data-subsub-cat-id="130"]::before {
    content: "\f7ca";
}
.icon[data-subsub-cat-id="123"]::before {
    content: "\f773";
}
.icon[data-subsub-cat-id="65"]::before {
    content: "\f7c2";
}
.icon[data-subsub-cat-id="76"]::before {
    content: "\f7c4";
}
.icon[data-subsub-cat-id="67"]::before {
    content: "\f1e6";
}
.icon[data-subsub-cat-id="85"]::before {
    content: "\f3fa";
}
.icon[data-subsub-cat-id="68"]::before {
    content: "\f0a0";
}
.icon[data-subsub-cat-id="69"]::before {
    content: "\f11c";
}
.icon[data-subsub-cat-id="70"]::before {
    content: "\f538";
}
.icon[data-subsub-cat-id="71"]::before {
    content: "\f51f";
}
.icon[data-subsub-cat-id="50"]::before {
    content: "\f030";
}
.icon[data-subsub-cat-id="52"]::before {
    content: "\f03d";
}
.icon[data-subsub-cat-id="73"]::before {
    content: "\f51b";
}
.icon[data-subsub-cat-id="53"]::before,
.icon[data-sub-cat-id="29"]::before {
    content: "\f26c";
}
.icon[data-subsub-cat-id="54"]::before {
    content: "\f3c9";
}
.icon[data-subsub-cat-id="55"]::before {
    content: "\f04b";
}
.icon[data-subsub-cat-id="56"]::before {
    content: "\f028";
}
.icon[data-subsub-cat-id="117"]::before {
    content: "\f130";
}
.icon[data-subsub-cat-id="118"]::before {
    content: "\f7c0";
}
.icon[data-subsub-cat-id="244"]::before {
    content: "\f001";
}
.icon[data-subsub-cat-id="266"]::before {
    content: "\f8d9";
}
.icon[data-subsub-cat-id="91"]::before {
    content: "\f526";
}
.icon[data-subsub-cat-id="48"]::before {
    content: "\f2a0";
}
.icon[data-subsub-cat-id="119"]::before {
    content: "\f1ac";
}
.icon[data-subsub-cat-id="49"]::before {
    content: "\f879";
}
.icon[data-subsub-cat-id="120"]::before {
    content: "\f897";
}
.icon[data-subsub-cat-id="121"]::before {
    content: "\f519";
}
.icon[data-subsub-cat-id="45"]::before {
    content: "\f15c";
}
.icon[data-subsub-cat-id="46"]::before {
    content: "\f0c5";
}
.icon[data-subsub-cat-id="238"]::before {
    content: "\f02a";
}
.icon[data-subsub-cat-id="239"]::before {
    content: "\f788";
}
.icon[data-subsub-cat-id="240"]::before {
    content: "\f543";
}
.icon[data-subsub-cat-id="241"]::before {
    content: "\f25a";
}
.icon[data-subsub-cat-id="242"]::before {
    content: "\f1ea";
}
.icon[data-subsub-cat-id="243"]::before {
    content: "\f1ec";
}
.icon[data-subsub-cat-id="296"]::before {
    content: "\f09d";
}
.icon[data-subsub-cat-id="124"]::before,
.icon[data-sub-cat-id="62"]::before {
    content: "\f2f2";
}
.icon[data-subsub-cat-id="125"]::before {
    content: "\f1e5";
}
.icon[data-subsub-cat-id="235"]::before {
    content: "\f533";
}
.icon[data-subsub-cat-id="236"]::before {
    content: "\f7c5";
}
.icon[data-sub-cat-id="21"]::before {
    content: "\f6e6";
}
.icon[data-sub-cat-id="132"]::before {
    content: "\f2cc";
}
.icon[data-sub-cat-id="24"]::before {
    content: "\f6c0";
}
.icon[data-sub-cat-id="123"]::before {
    content: "\f187";
}
.icon[data-sub-cat-id="121"]::before {
    content: "\f6cf";
}
.icon[data-sub-cat-id="79"]::before {
    content: "\f863";
}
.icon[data-sub-cat-id="37"]::before {
    content: "\f51a";
}
.icon[data-sub-cat-id="151"]::before {
    content: "\f51c";
}
.icon[data-sub-cat-id="28"]::before {
    content: "\f0db";
}
.icon[data-sub-cat-id="27"]::before {
    content: "\f236";
}
.icon[data-sub-cat-id="26"]::before {
    content: "\f59d";
}
.icon[data-sub-cat-id="25"]::before {
    content: "\f4b8";
}
.icon[data-sub-cat-id="23"]::before {
    content: "\f86d";
}
.icon[data-sub-cat-id="22"]::before {
    content: "\f0eb";
}
.icon[data-sub-cat-id="133"]::before {
    content: "\f5ba";
}
.icon[data-sub-cat-id="156"]::before {
    content: "\f02d";
}
.icon[data-sub-cat-id="93"]::before {
    content: "\f4c0";
}
.icon[data-sub-cat-id="152"]::before {
    content: "\f4bd";
}
.icon[data-sub-cat-id="94"]::before,
.icon[data-subsub-cat-id="268"]::before {
    content: "\f53c";
}
.icon[data-sub-cat-id="95"]::before,
.icon[data-subsub-cat-id="267"]::before {
    content: "\f571";
}
.icon[data-subsub-cat-id="90"]::before {
    content: "\f3df";
    font-family: "Line Awesome Brands" !important;
    font-weight: normal !important;
}

.icon[data-subsub-cat-id="300"]::before {
    content: "\f482";
}

.icon[data-subsub-cat-id="63"]::before,
.icon[data-subsub-cat-id="79"]::before {
    content: "\f613";
}

.icon[data-subsub-cat-id="92"]::before,
.icon[data-subsub-cat-id="93"]::before,
.icon[data-subsub-cat-id="94"]::before {
    content: "\f64f";
}
.icon[data-subsub-cat-id="95"]::before,
.icon[data-subsub-cat-id="96"]::before,
.icon[data-subsub-cat-id="97"]::before {
    content: "\f015";
}
.icon[data-subsub-cat-id="98"]::before,
.icon[data-subsub-cat-id="99"]::before,
.icon[data-subsub-cat-id="100"]::before {
    content: "\f69b";
}
.icon[data-subsub-cat-id="101"]::before,
.icon[data-subsub-cat-id="102"]::before,
.icon[data-subsub-cat-id="103"]::before {
    content: "\f1ad";
}
.icon[data-subsub-cat-id="103"]::before,
.icon[data-subsub-cat-id="104"]::before,
.icon[data-subsub-cat-id="105"]::before {
    content: "\f065";
}
.icon[data-subsub-cat-id="106"]::before,
.icon[data-subsub-cat-id="107"]::before,
.icon[data-subsub-cat-id="108"]::before {
    content: "\f54f";
}
.icon[data-subsub-cat-id="109"]::before,
.icon[data-subsub-cat-id="110"]::before,
.icon[data-subsub-cat-id="111"]::before {
    content: "\f6f1";
}
.icon[data-subsub-cat-id="112"]::before,
.icon[data-subsub-cat-id="113"]::before {
    content: "\f494";
}
.icon[data-subsub-cat-id="114"]::before,
.icon[data-subsub-cat-id="115"]::before,
.icon[data-subsub-cat-id="116"]::before {
    content: "\f275";
}

.icon[data-sub-cat-id="30"]::before {
    content: "\f46a";
}
.icon[data-sub-cat-id="131"]::before {
    content: "\f815";
}
.icon[data-sub-cat-id="78"]::before {
    content: "\f73b";
}
.icon[data-sub-cat-id="49"]::before {
    content: "\f5a7";
}
.icon[data-sub-cat-id="48"]::before {
    content: "\f2e7";
}
.icon[data-sub-cat-id="47"]::before {
    content: "\f517";
}
.icon[data-sub-cat-id="46"]::before {
    content: "\f0f4";
}
.icon[data-sub-cat-id="33"]::before {
    content: "\f553";
}
.icon[data-sub-cat-id="32"]::before {
    content: "\f562";
}
.icon[data-sub-cat-id="31"]::before {
    content: "\f2dc";
}
.icon[data-sub-cat-id="150"]::before {
    content: "\f1b8";
}
.icon[data-sub-cat-id="57"]::before {
    content: "\f290";
}
.icon[data-sub-cat-id="122"]::before {
    content: "\f2cd";
}
.icon[data-sub-cat-id="119"]::before {
    content: "\f0f2";
}
.icon[data-sub-cat-id="81"]::before {
    content: "\f61f";
}
.icon[data-sub-cat-id="80"]::before {
    content: "\f5bd";
}
.icon[data-sub-cat-id="63"]::before {
    content: "\f530";
}
.icon[data-sub-cat-id="61"]::before {
    content: "\f70b";
}
.icon[data-sub-cat-id="60"]::before {
    content: "\f52d";
}
.icon[data-sub-cat-id="59"]::before {
    content: "\f696";
}
.icon[data-sub-cat-id="58"]::before {
    content: "\f54b";
}
.icon[data-sub-cat-id="130"]::before {
    content: "\f5bb";
}
.icon[data-sub-cat-id="68"]::before {
    content: "\f6be";
}
.icon[data-sub-cat-id="69"]::before {
    content: "\f1e3";
}
.icon[data-sub-cat-id="71"]::before {
    content: "\f254";
}
.icon[data-sub-cat-id="174"]::before {
    content: "\f3ff";
}
.icon[data-sub-cat-id="36"]::before {
    content: "\f0c4";
}
.icon[data-sub-cat-id="154"]::before {
    content: "\f02c";
}
.icon[data-sub-cat-id="120"]::before {
    content: "\f578";
}
.icon[data-sub-cat-id="101"]::before {
    content: "\f7d9";
}
.icon[data-sub-cat-id="100"]::before {
    content: "\f55d";
}
.icon[data-sub-cat-id="89"]::before {
    content: "\f44b";
}
.icon[data-sub-cat-id="88"]::before {
    content: "\f6bb";
}
.icon[data-sub-cat-id="83"]::before {
    content: "\f303";
}
.icon[data-sub-cat-id="64"]::before {
    content: "\f0f1";
}
.icon[data-sub-cat-id="50"]::before {
    content: "\f54a";
}
.icon[data-sub-cat-id="45"]::before {
    content: "\f3ed";
}
.icon[data-sub-cat-id="38"]::before {
    content: "\f51a";
}
.icon[data-sub-cat-id="39"]::before {
    content: "\f1e6";
}
.icon[data-sub-cat-id="40"]::before {
    content: "\f4d8";
}
.icon[data-sub-cat-id="41"]::before {
    content: "\f5ae";
}
.icon[data-sub-cat-id="42"]::before {
    content: "\f303";
}
.icon[data-sub-cat-id="43"]::before {
    content: "\f610";
}
.icon[data-sub-cat-id="44"]::before {
    content: "\f085";
}
.icon[data-sub-cat-id="155"]::before,
.icon[data-sub-cat-id="153"]::before {
    content: "\f6e3";
}
.icon[data-sub-cat-id="65"]::before {
    content: "\f61f";
}
.icon[data-sub-cat-id="66"]::before {
    content: "\f7b5";
}
.icon[data-sub-cat-id="67"]::before {
    content: "\f77d";
}
.icon[data-sub-cat-id="124"]::before {
    content: "\f490";
}
.icon[data-sub-cat-id="125"]::before {
    content: "\f000";
}
.icon[data-sub-cat-id="126"]::before {
    content: "\f786";
}
.icon[data-sub-cat-id="127"]::before {
    content: "\f461";
}
.icon[data-sub-cat-id="128"]::before {
    content: "\f0f4";
}
.icon[data-sub-cat-id="129"]::before {
    content: "\f4d8";
}
.icon[data-sub-cat-id="90"]::before {
    content: "\f562";
}
.icon[data-sub-cat-id="104"]::before {
    content: "\f722";
}
.icon[data-sub-cat-id="117"]::before {
    content: "\f4ce";
}
.icon[data-sub-cat-id="116"]::before {
    content: "\f49e";
}
.icon[data-sub-cat-id="115"]::before {
    content: "\f1e6";
}
.icon[data-sub-cat-id="114"]::before {
    content: "\f494";
}
.icon[data-sub-cat-id="113"]::before {
    content: "\f545";
}
.icon[data-sub-cat-id="112"]::before {
    content: "\f0c3";
}
.icon[data-sub-cat-id="111"]::before {
    content: "\f01c";
}
.icon[data-sub-cat-id="110"]::before {
    content: "\f7e5";
}
.icon[data-sub-cat-id="109"]::before {
    content: "\f275";
}
.icon[data-sub-cat-id="108"]::before {
    content: "\f807";
}
.icon[data-sub-cat-id="107"]::before {
    content: "\f5c3";
}
.icon[data-sub-cat-id="106"]::before {
    content: "\f5bc";
}
.icon[data-sub-cat-id="105"]::before {
    content: "\f63b";
}
.icon[data-sub-cat-id="53"]::before {
    content: "\f5aa";
}
.icon[data-sub-cat-id="134"]::before {
    content: "\f5ca";
}
.icon[data-sub-cat-id="51"]::before {
    content: "\f2cd";
}
.icon[data-sub-cat-id="52"]::before {
    content: "\f863";
}
.icon[data-sub-cat-id="54"]::before {
    content: "\f52b";
}
.icon[data-sub-cat-id="55"]::before {
    content: "\f773";
}
.icon[data-sub-cat-id="56"]::before {
    content: "\f06b";
}
.icon[data-sub-cat-id="70"]::before {
    content: "\f02d";
}
.icon[data-sub-cat-id="73"]::before {
    content: "\f7a6";
}
.icon[data-sub-cat-id="82"]::before {
    content: "\f304";
}
.icon[data-sub-cat-id="84"]::before {
    content: "\f144";
}
.icon[data-sub-cat-id="87"]::before {
    content: "\f008";
}
.icon[data-sub-cat-id="118"]::before {
    content: "\f0d0";
}
.icon[data-sub-cat-id="135"]::before {
    content: "\f5de";
}
.icon[data-sub-cat-id="149"]::before {
    content: "\f0c5";
}
.icon[data-sub-cat-id="148"]::before {
    content: "\f072";
}
.icon[data-sub-cat-id="146"]::before {
    content: "\f4c0";
}
.icon[data-sub-cat-id="145"]::before {
    content: "\f6fa";
}
.icon[data-sub-cat-id="144"]::before {
    content: "\f48e";
}
.icon[data-sub-cat-id="143"]::before {
    content: "\f0ad";
}
.icon[data-sub-cat-id="142"]::before {
    content: "\f75b";
}
.icon[data-sub-cat-id="141"]::before {
    content: "\f71e";
}
.icon[data-sub-cat-id="139"]::before {
    content: "\f51c";
}
.icon[data-sub-cat-id="138"]::before {
    content: "\f5b6";
}
.icon[data-sub-cat-id="137"]::before {
    content: "\f557";
}
.icon[data-sub-cat-id="136"]::before {
    content: "\f7bf";
}


.icon[data-sub-cat-id="35"]::before,
.icon[data-sub-cat-id="34"]::before,
.icon[data-sub-cat-id="98"]::before,
.icon[data-sub-cat-id="99"]::before,
.icon[data-sub-cat-id="96"]::before,
.icon[data-sub-cat-id="72"]::before,
.icon[data-sub-cat-id="97"]::before,
.icon[data-sub-cat-id="147"]::before,
.icon[data-subsub-cat-id="47"]::before,
.icon[data-subsub-cat-id="57"]::before,
.icon[data-subsub-cat-id="74"]::before,
.icon[data-subsub-cat-id="75"]::before,
.icon[data-subsub-cat-id="77"]::before,
.icon[data-subsub-cat-id="78"]::before,
.icon[data-subsub-cat-id="80"]::before,
.icon[data-subsub-cat-id="81"]::before,
.icon[data-subsub-cat-id="82"]::before,
.icon[data-subsub-cat-id="83"]::before,
.icon[data-subsub-cat-id="84"]::before,
.icon[data-subsub-cat-id="126"]::before,
.icon[data-sub-cat-id="12"]::before,
.icon[data-sub-cat-id="19"]::before {
    content: "\f141";
}


/* Color */

.color-range {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 1px solid #dddddd;
    display: inline-block;
    margin-right: 5px;
}