.BrowseTop {
    /*padding: 30px 15px;*/
    position: relative;
}

.BrowseTop h1 {
    margin: 0;
    padding: 0;
    font-size: 20px;
}

.BrowseFilter {
    background: white;
    position: fixed;
    left: -100%;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    transition: .5s all ease;
    overflow-y: auto;
    overflow-y: auto;
}

.BrowseFilterWrapper {
    padding: 15px 50px;
    position: relative;
}

.BrowseFilterActive {
    left: 0;
}

.NoAds {
    margin: 0 15px;
}

.BrowseFilterContainer {
    margin: 15px;
}

.BrowseBack {
    font-size: 14px;
    display: flex;
    align-items: center;
}

.BrowseBack span {
    font-size: 14px;
    margin-left: 5px;
}

.BrowseFilterButton {
    margin-top: 50px;
}

.BrowseFilterRange {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}

.BrowseFilterRange span {
    font-size: 14px;
    width: 95px;
    text-align: center;
    white-space: nowrap;
}

.BrowseFilterRange bdi {
    font-size: 12px;
    display: flex;
    width: 150px;
    align-items: center;
    justify-content: center;
    color: #a1a1a1;
}

.BrowseFilterRange bdi::after,
.BrowseFilterRange bdi::before {
    content: "";
    width: 100%;
    height: 1px;
    background: #dfdfdf;
    display: inline-block;
    margin: 0 5px;
}

.BrowseFilterRange ins {
    font-size: 11px;
    color: #a1a1a1;
}

.SideSubTitle {
    color: gray;
}