.HomeContainer {
    border-radius: 25px 25px 0 0;
    background: #F4F4F4;
    overflow: hidden;
    padding-bottom: 100px;
}

.AdsItems {
    margin: 0 15px 80px 15px;
    padding: 10px 0;
}

.HomeCategories {
    white-space: nowrap;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    margin: 0;
    padding: 10px 15px;
    background: #F4F4F4;
    position: relative;

    & li {
        width: 100%;
        background: #ededed;
        border-radius: 15px;
        border: 1px solid #e5e5e5;
        padding: 10px 15px;
        overflow: unset;
        margin: 0 3px;

        & a {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
        }

        & i {
            font-size: 30px;
            display: flex;
        }

        & span {
            display: block;
            width: 100%;
            font-size: 13px;
            text-align: center;
        }
    }
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.CatLoading {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 10px 15px;

    & li {
        width: 100%;
        height: 75px;
        border: 1px solid #e5e5e5;
        border-radius: 15px;
        background: linear-gradient(-45deg, #e5e5e5, white, #e5e5e5, white);
        background-size: 400% 400%;
        animation: gradient 2s ease infinite;
        margin: 0 3px;
    }
}