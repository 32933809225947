@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.AdImage {
    height: 250px;
    overflow: hidden;
    background: linear-gradient(-45deg, #ffffff, #cfcfcf, #bdbdbd, #cfcfcf);
    background-size: 400% 400%;
    animation: gradient 2s ease infinite;

    & img {
        min-height: 300px;
    }
}

.AdsImageDefault {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Ads {
    background: #ffffff;
    padding: 8px;
    border-radius: 10px;
    position: relative;

    & h2 {
        font-size: 13px;
        margin: 0;
        color: var(--color-gray);
        font-weight: normal;
    }

    & img {
        order-radius: 8px;
    }
}

.AdsPriceView {
    margin: 5px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & strong span {
        color: var(--color-green);
        font-size: 15px;
        margin-right: 5px;
    }

    & bdi {
        font-size: 13px;
        font-weight: normal;
    }
}

.AdsPrice {
    font-size: 12px;
    text-align: left;
    color: var(--color-green);
    border-bottom: 1px solid #f4f4f4;
    padding-bottom: 5px;
    margin: 7px 0;

    & bdi {
        font-size: 10px;
    }
}

.AdsLocation,
.AdsCat {
    display: flex;
    align-items: center;

    & a {
        display: inline-block;
        font-size: 12px;
        color: darkgrey!important;
    }

    & i {
        color: darkgray;
        margin-left: 3px;
        display: flex;
    }
}

.AdsTypeView {
    position: relative!important;
    right: 0!important;
    top: 0!important;
    font-size: 12px!important;
}

.AdsType {
    font-size: 11px;
    padding: 0 5px;
    color: #fff;
    margin-right: 5px;
    border-radius: 3px;
    position: absolute;
    right: 8px;
    top: 15px;
}

.AdsTypeNew {
    background: #2196F3!important;
}

.AdsTypeUsed {
    background: #607D8B!important;
}

.AdsTypeService {
    background: #eca013!important;
}

.AdsViewContainer {
    padding: 15px;
    background: white;
    margin: 15px 15px 0 15px;
    border-radius: 10px;

    &  h1 {
        font-size: 15px;
        margin: 0;
        padding: 0 0 0 10px;
    }
}

.AdsCategory,
.AdsLoc {
    display: flex;

    & span {
        margin-left: 5px;
    }

    & ul {
        margin: 0;
        padding: 0;
        white-space: nowrap;
        overflow-x: auto;

        & li a {
            color: var(--color-gray)!important;
            font-size: 13px;
        }
    }
}

.AdsCategory i,
.AdsLoc i,
.AdsDate i,
.AdsPriceView i,
.AdsFeatures li i {
    font-size: 18px;
    color: var(--color-gray)
}

.AdsCall {
    border: 1px solid var(--color-red);
    text-align: center;
    color: var(--color-red)!important;
    border-radius: 5px;
    padding: 10px 0;
    background: white;
    height: auto;

    & span {
        display: inline-block;
        font-size: 14px;
    }

    & i {
        font-size: 25px;
    }
}

.AdsImage {
    position: relative;
}

.AdsDate {
    color: var(--color-gray);
    font-size: 12px;

    & time {
        margin-right: 2px;
    }
}

.AdsTopData {
    border-bottom: 1px solid #dddddd;
    padding: 10px 0;
}

.AdsTools {
    background: rgb(0 0 0 / 30%);
    border-radius: 6px;
    position: absolute;
    top: 20px;
    left: 12px;
    z-index: 1;
    padding: 5px 10px;

    & li:first-child {
        border-left: 1px solid rgb(255 255 255 / 40%);
        margin-left: 10px;
        padding-left: 10px;
    }

    & li i {
        font-size: 20px;
        color: white;
        display: flex;
    }
}

.AdsDesc {
    border-bottom: 1px solid #dddddd;
    padding: 10px 0;
    margin-bottom: 10px;

    & p {
        font-size: 14px;
    }
}

.AdsFeatures li span {
    font-size: 14px;
    font-weight: bold;
    margin-right: 5px;
}

.AdsFeatures li bdi {
    font-size: 14px;
    margin-right: 5px;
}

.AdsSellType {
    display: block;
    font-size: 13px;

    & span {
        margin-right: 5px;
        font-size: 12px;
        color: var(--color-gray)
    }
}

.ViewAdsAdditional li {
    margin-bottom: 5px;
}